import { computed } from 'vue'
import storeService from '@/store/storeService'
import { $toggles } from '@/services'
import Instance from '@/models/Instance'

export default function useInstanceLoader() {
  const instances = computed(() => Instance.query().get())
  const loadInstances = async () => {
    await storeService.get().dispatch('instance/populate')
  }

  const { data: sessionData } = useAuth()
  const instanceLogin = computed(() => sessionData.value?.provider === 'instance')
  const instanceCreateEnabled = computed(() => !instanceLogin.value || $toggles.allowCreateInstanceFromInstanceLogin)

  return {
    instances,
    userInstances: instances,
    loadInstances,
    instanceLogin,
    instanceCreateEnabled
  }
}
